import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography, Box, Grid, Link } from '@material-ui/core';
import CopyRight from 'components/CopyRight';

const useStyles = makeStyles((theme: Theme) => ({
    innerContainer: {
        padding: '40px 16px',
        [theme.breakpoints.down(768.9)]: {
            padding: '28px',
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    moeDesktopDf: {
        maxWidth: '73.125rem ',
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        border: '1px solid #d4d5d9',
        boxShadow: '0 2px 8px hsla(0,0%,86.7%,.7)',
        borderRadius: '4px',
        paddingBottom: '1.5rem',
        paddingLeft: '2rem',
        justifyContent: 'center',
        [theme.breakpoints.up(769)]: {
            /* Styles for screens with min-width of 1020px */
            display: 'flex',
        },
    },
    moeLinkBlock: {
        paddingRight: '2rem',
        paddingTop: '2rem',
        width: '25%',
        textAlign: 'left',
        [theme.breakpoints.down(768.9)]: {
            /* Styles for screens with min-width of 1020px */
            width: '100%',
        },
    },
}));

const LinkBlock: FC<{ domContainerId: string; menuTitle: string }> = props => {
    const classes = useStyles();

    return (
        <div id={props.domContainerId} className={classes.moeLinkBlock}>
            <span style={{ fontWeight: 'bolder', fontSize: '1.2rem' }}>{props.menuTitle}</span>
            <ul>
                <li>
                    <a href="https://dashboard.personalise.gov.sg">Calendar</a>
                </li>
                <li>
                    <a href="https://dashboard.personalise.gov.sg">National examinations date</a>
                </li>
            </ul>
        </div>
    );
};

const Moe: FC = () => {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.innerContainer} elevation={1}>
                <Box textAlign="center">
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography align="left" variant="h1" style={{ paddingBottom: 24 }}>
                                Playground MOE Demo Page
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <Link href="/faq" style={{ textDecoration: 'none' }}>
                                <Typography variant="h1" style={{ paddingBottom: 24 }}>
                                    FAQ
                                </Typography>
                            </Link>
                            <Link href="/" style={{ textDecoration: 'none' }}>
                                <Typography variant="h1" style={{ paddingBottom: 24 }}>
                                    Main
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    <div className="content" style={{ paddingBottom: 1000 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ paddingTop: 300 }}>
                                <div className="header">
                                    <Typography variant="h3" style={{ paddingBottom: 24 }}>
                                        Ministry of Education
                                    </Typography>
                                    <Typography variant="subtitle1" style={{ paddingBottom: 200 }}>
                                        Moulding the future of our nation.
                                    </Typography>
                                </div>
                                <Grid alignItems="center" direction="column" justify="center">
                                    <div className="body">
                                        <div
                                            id="searchsg-searchbar"
                                            data-client-id="c1fa2992-c5a7-4481-9abb-e4ef6b97b1eb"
                                            data-override-display-name="MOE"
                                            data-display-new="true"
                                        />
                                        <div className={classes.moeDesktopDf}>
                                            <LinkBlock
                                                domContainerId="searchsg-smartrecommend-app-cc42d41e-5d46-41fa-9074-9f364ae8d1ff"
                                                menuTitle="Academic Calendar"
                                            />
                                            <LinkBlock
                                                domContainerId="searchsg-smartrecommend-app-0c0e634c-682f-4464-897a-d6590247f0e8"
                                                menuTitle="Admissions"
                                            />
                                            <LinkBlock
                                                domContainerId="searchsg-smartrecommend-app-e496b2db-82dd-469b-81e4-7b4e6dbafba2"
                                                menuTitle="Career"
                                            />
                                            <LinkBlock
                                                domContainerId="searchsg-smartrecommend-app-4683afe9-6c35-4152-a6a4-fcadcf997d87"
                                                menuTitle="Self-help tools"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                maxWidth: '73.125rem ',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                marginTop: '50px',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            ⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄
                                            <div id="searchsg-smartrecommend-app-8c88d97d-2964-49e9-9f6e-578a7959b440" />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Paper>
            <CopyRight />
        </div>
    );
};

export default Moe;
